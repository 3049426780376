.not-found-cont{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    height: 100vh;
    width: 100%;
}

.not-found-cont img {
    height: 300px;
}

@media (max-width: 576px){
    .not-found-cont img{
        height: 150px;
    }
}