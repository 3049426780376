/* Main container for table */
.main-req {
    margin: 2.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
  }
  
  /* Button styling */
  .delete-btn {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  
  .delete-btn:hover {
    background-color: #e60000;
  }
  
  /* No requests found */
  .noreq-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    min-height: 80vh;
  }
  
  .noreq-img {
    height: 200px;
    margin-bottom: 20px;
  }
  
  .noreq {
    font-size: 18px;
    color: orange;
    text-align: center;
    margin-top: 10px;
  }
  
  /* Card layout for smaller screens */
  .card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;
    width: 100%;
  }
  
  .card {
    background-color: #ffffff;
    padding: 20px;
    width: 30%;
    max-width: 300px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .card:hover {
    transform: scale(1.02);
  }
  
  .card h3 {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .card p {
    font-size: 14px;
    color: #555;
  }
  
  .card .delete-btn {
    width: 100%;
    margin-top: 10px;
  }
  
  /* Message container */
  .msg-container {
    max-height: 3.6em; /* Roughly 2 lines of text */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    -webkit-line-clamp: 2; /* Truncate to 2 lines */
    cursor: pointer;
    transition: max-height 0.3s ease;
  }
  
  .msg-container.show {
    line-clamp: unset;
    -webkit-line-clamp: unset; /* Show full text when clicked */
    max-height: none; /* Remove max-height restriction */
    white-space: normal; /* Allow message to break lines */
  }

  .loading-view{
    height: 300px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Responsive Layout */
  @media (max-width: 768px) {
    .card-container {
      justify-content: center;
      margin-top: 20px;
      gap: 1rem;
    }
  
    .card {
      width: 100%;
      max-width: 100%;
    }
  }
  