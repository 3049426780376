.signup-bg{
    width: 100%;
}
.signup-cont{
    background-color: black;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}
.signup-card{
    border: 1px solid gray;
    border-radius: 8px;
    padding: 2rem;
    width: 400px;
}
.form-cont{
    width: 100%;
}
.l-head{
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: bold;
}
.label-ele1{
    color: #ffffff;
    font-size: 1rem;
    margin-bottom: 5px;
    margin-right: 7px;
}
.s-box{
    width: 100%;
    background-color: transparent;
    outline: none;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 2px 1rem;
    height: 30px;
    margin-bottom: 1rem;
    color: #ffffff;
}
.btn-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.signup-btn{
    background-color: green;
    height: 30px;
    width: 48%;
    border: none;
    border-radius: 5px;
    color: #ffffff;
    padding-bottom: 3px;
    margin-top: 6px;
}

.log{
    width: 48%;
}
.log-btn{
    background-color: rgb(47, 123, 255);
    height: 30px;
    width: 100%;
    border: none;
    border-radius: 5px;
    color: #ffffff;
    padding-bottom: 3px;
    margin-top: 6px;
}
.role-row{
    display: flex;
    margin: 0.7rem 0rem;
}
.radio{
    outline: none;
    margin-right: 4px;
}
.err{
    color: red;
    font-size: 0.9rem;
}
.suc{
    color: green;
    font-size: 0.9rem;
}

@media (max-width: 576px){
    .signup-card{
        width: 90%;
        padding: 1rem;
    }
}