.login-btn{
    width: 48%;
    background-color: rgb(47, 123, 255);
    height: 30px;
    border: none;
    border-radius: 5px;
    color: #ffffff;
    padding-bottom: 3px;
    margin-top: 6px;
}
.sign-btn{
    background-color: green;
    height: 30px;
    width: 100%;
    border: none;
    border-radius: 5px;
    color: #ffffff;
    padding-bottom: 3px;
    margin-top: 6px;
}
.err{
    color: red;
    font-size: 14px;
}