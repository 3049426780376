.contact-container{
    padding: 2rem;
    margin: 2rem;
    display: flex;
    flex-direction: column;
    background-color: rgb(20, 20, 20);
    max-width: 95%;
}
.contact-card-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5rem;
}
.form-container{
    border: 1px solid gray;
    border-radius: 6px;
    width: 40%;
    padding: 2rem;
}
.contact-data{
    width: 54%;
    font-size: 1.2rem;
}
.form-para{
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
}
.box-cont{
    width: 100%;
    margin-bottom: 1.5rem;
    display: flex;
}
.box1{
    border: 1px solid gray;
    background-color: transparent;
    border-radius: 4px;
    padding: 5px 10px;
    width: 40%;
    margin-right: 1rem;
    color: #ffffff;
}
.box2{
    border: 1px solid gray;
    background-color: transparent;
    border-radius: 4px;
    padding: 5px 10px;
    width: 55%;
    color: #ffffff;
}
textarea{
    background-color: transparent;
    border: 1px solid gray;
    border-radius: 4px;
    padding: 10px;
    color: #ffffff;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.app-logo-mini{
    height: 40px;
    margin-bottom: 1rem;
}
option{
    background-color: black;
}
.talk-btn{
    background-color: rgb(43, 182, 43);
}
@media (max-width: 400px){
    .contact-container{
        padding: 1.4rem;
        margin: 0;
        max-width: 100%;
    }
    .contact-card-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 0.8rem;
    }
    .form-container{
        width: 100%;
        order: -1;
        padding: 1rem;
        margin-bottom: 1rem;
    }
    .contact-data{
        width: 100%;
        font-size: 0.8rem;
    }
    .box-cont{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0.8rem;
    }
    .box1{
        width: 100%;
        margin-bottom: 0.8rem;
        height: 25px;
        font-size: 0.8rem;
    }
    .box2{
        width: 100%;
        height: 25px;
        font-size: 0.8rem;
    }
    textarea{
        margin-top: 0.2rem;
        margin-bottom: 0.5rem;
        padding: 0.3rem;
        font-size: 0.9rem;
    }
    #services{
        font-size: 0.8rem;
        padding: 0rem 10px;
    }
    .talk-btn{
        height: 25px;
        font-size: 0.8rem;
        padding: 0.1rem;
    }
}
@media (min-width: 400px) and (max-width: 568px){
    .contact-container{
        padding: 1.4rem;
        margin: 0;
        max-width: 100%;
    }
    .contact-card-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .form-container{
        width: 100%;
        order: -1;
        padding: 1rem;
        margin-bottom: 1.4rem;
    }
    .contact-data{
        width: 100%;
        font-size: 1rem;
        font-weight: 400;
    }
}
.pp{
    color: green;
    font-size: 0.9rem;
}
.pp1{
    color: red;
    font-size: 0.9rem;
}
@media (min-width: 568px) and (max-width: 768px){
    .contact-container{
        padding: 2rem;
        margin: 2rem;
    }
    .contact-card-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .form-container{
        width: 100%;
        order: -1;
        padding: 1.6rem;
        margin-bottom: 1.4rem;
        max-width: 500px;
    }
    .contact-data{
        width: 100%;
        font-size: 1rem;
        font-weight: 400;
    }
}