.web-container{
    background-color: black;
    width: 100%;
    padding-top: 5.4rem;
}
.web-card-container{
    margin: 2rem;
    padding: 2rem;
    background-color: rgb(20, 20, 20);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.web-content-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 1.6rem;
}
.web-content{
    display: flex;
    flex-direction: column;
    width: 50%;
    border-radius: 8px;
    padding: 1.5rem;
}
.web-content1{
    display: flex;
    flex-direction: column;
    width: 50%;
    border-radius: 8px;
    padding: 1.5rem;
    order: -1;
}
.service-data-img{
    height: 200px;
    width: 320px;
    max-width: 100%;
}
.uni-head{
    color: #ffffff;
    font-size: 1.3rem;
    text-align: center;
}
.uni-des{
    color: #ffffff;
    font-size: 1rem;
    font-weight: 400;
}

@media (max-width: 567px){
    .web-card-container{
        padding: 1.4rem;
        margin: 1.4rem;
    }
    .web-content-container{
        display: flex;
        flex-direction: column;
        margin-top: 0.7rem;
    }
    .web-content{
        width: 100%;
        margin-top: 1rem;
        border: none;
        padding: 0;
    }
    .web-content1{
        width: 100%;
        margin-top: 1rem;
        order: 0;
        border: none;
        padding: 0;
    }
    .uni-head{
        font-size: 1rem;
    }
    .uni-des{
        font-size: 0.9rem;
    }
}
@media (min-width: 568px) and (max-width: 768px){
    .web-content-container{
        display: flex;
        flex-direction: column;
        margin-top: 0.7rem;
    }
    .web-content{
        width: 100%;
        margin-top: 2rem;
        border: none;
        padding: 0;
    }
    .web-content1{
        width: 100%;
        margin-top: 2rem;
        order: 0;
        border: none;
        padding: 0;
    }
    .uni-head{
        font-size: 1.1rem;
    }
    .uni-des{
        font-size: 1rem;
    }
    .service-data-img{
        width: 500px;
        height: 280px;
    }
}
@media (min-width: 768px) and (max-width: 992){
    .web-content-container{
        justify-content: space-between;
    }
}