.request-item-cont{
    padding: 1rem;
    margin: 1rem;
    width: 30%;
    background-color: rgb(20, 20, 20);
    display: flex;
    flex-direction: column;
}

.req-data{
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 8px;
}

.del-btn{
    background-color: rgb(254, 149, 2);
    border: none;
    border-radius: 5px;
    width: 80px;
    height: 30px;
    color: #fff;
}

@media (max-width: 576px){
    .request-item-cont{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0.5rem;
        width: 100%;
    }
    .req-data{
        padding-top: 0;
        margin-bottom: 8px;
        font-size: 0.8rem;
    }
}

@media (min-width: 577px) and (max-width: 768px){
    .request-item-cont{
        width: 45%;
    }
}